import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'prospecto',
        loadChildren: './component/prospecto/prospecto.module#ProspectoModule'
      },
      {
        path: 'cotizacion',
        loadChildren: './component/cotizacion/cotizacion.module#CotizacionModule'
      },
      {
        path: 'editarCotizacion',
        loadChildren: './component/cotizacion/editarCotizacion.module#EditarCotizacionModule'
      },
      {
        path: 'cliente',
        loadChildren: './component/cliente/cliente.module#ClienteModule'
      },
      {
        path: 'ClienteConfiguracion',
        loadChildren: './component/cliente/configuracion.cliente.module#ConfiguracionClienteModule'
      },
      {
        path: 'sucursal',
        loadChildren: './component/sucursal/sucursal.module#SucursalModule'
      },
      {
        path: 'contrato',
        loadChildren: './component/contrato/contrato.module#ContratoModule'
      },
      {
        path: 'calendario',
        loadChildren: './component/calendario/calendario.module#CalendarioModule'
      },
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule'
      },
      {
        path: 'vistacontrato',
        loadChildren: './component/contratovista/contratovista.module#ContratovistaModule'
      },
      {
        path: 'services-list',
        loadChildren: './component/mobile-application/services-client/services-client.module#ServicesClientModule'
      },
      {
        path: 'monitoring-types',
        loadChildren: './component/mobile-application/monitoring-types/monitoring-types.module#MonitoringTypesModule'
      },
      {
        path: 'monitoring-options',
        loadChildren: './component/mobile-application/mon-control-roedores/services-option/services-option.module#ServicesOptionModule'
      },
      {
        path: 'material-used',
        loadChildren: './component/mobile-application/mon-control-roedores/material-used/material-used.module#MaterialUsedModule'
      },
      {
        path: 'report-cr',
        loadChildren: './component/mobile-application/mon-control-roedores/monitoring-report-cr/monitoring-report-cr.module#MonitoringReportCrModule'
      },
      {
        path: 'reports-printer',
        loadChildren: './component/mobile-application/reports-printer/reports-printer.module#ReportsPrinterModule'
      },
      {
        path: 'monitoring-option-2',
        loadChildren: './component/mobile-application/inspect-aplica-plaguicidas/services-option/services-option.module#ServicesOptionModule'
      },
      {
        path: 'inspection',
        loadChildren: './component/mobile-application/inspect-aplica-plaguicidas/inspeccion/inspeccion.module#InspeccionModule'
      },
      {
        path: 'report-iap',
        loadChildren: './component/mobile-application/inspect-aplica-plaguicidas/monitoring-report-iap/monitoring-report-insp.module#MonitoringReportInspModule'
      },
      {
        path: 'material-used-2',
        loadChildren: './component/mobile-application/inspect-aplica-plaguicidas/material-used/material-used.module#MaterialUsedModule'
      },
      {
        path: 'monitoring-option-3',
        loadChildren: './component/mobile-application/mon-control-insectos-vol/services-option/services-option.module#ServicesOptionModule'
      },
      {
        path: 'monitoring-civ',
        loadChildren: './component/mobile-application/mon-control-insectos-vol/monitoring-report-civ/monitoring-report-civ.module#MonitoringReportCivModule'
      }



    ]
  },
  {
    path: '**',
    redirectTo: '/login'
  },
  {
    path: 'login',
    component: LoginComponent,
  }
];
