import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map'

@Injectable()
export class MyserviceService {

  constructor(private httpClient : HttpClient) { }

  checkusernameandpassword(uname: string, pwd: string) {

    const params = new HttpParams({
      fromObject: {
        user_email: uname,
        user_password: pwd,
        language_id: '1'
      }
    });

    return this.httpClient.post('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/both/login',params, {
      headers: new HttpHeaders({
           'Content-Type':  'application/x-www-form-urlencoded',
         })
    }).map(data=>
     data); 

  }
  checkUsername(uname: string) {

    const params = new HttpParams({
      fromObject: {
        user_email: uname,
        user_password: 'gth', //fijo en calidad de mientras para probar en lo que adan genera el metodo en la api
        language_id: '1'
      }
    });

    return this.httpClient.post('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/both/login',params, {
      headers: new HttpHeaders({
           'Content-Type':  'application/x-www-form-urlencoded',
         })
    }).map(data=>
     data);

  }    

  obtenerMenu() { 

    const params = new HttpParams({
      fromObject: {
        token: 'uname',
        language_id: 'pwd'
      }
    });

    return this.httpClient.get('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/menu',{
      headers: {'token':sessionStorage.getItem('access_token') , 'language_id': '1'} }).map(data=>
        data);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('username');
  }

  refreshToken(access_token: string, refresh_token: string) {
    console.log("Funcion refresh");
    const params = new HttpParams({
      fromObject: {
        token: access_token,
        refresh_token: refresh_token
      }
    });
    return this.httpClient.post('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/both/refresh-token',
      params,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      }).do((authResult: any) => {
        sessionStorage.setItem('access_token', authResult.data.access_token);
        sessionStorage.setItem('refresh_token', authResult.data.refresh_token);
      })
      .shareReplay();
  }

}
