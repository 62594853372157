import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyserviceService } from 'src/app/services/myservice.service';
import { Menu } from 'src/app/model/menuModel';
import { map } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  providers: [MyserviceService]
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private service: MyserviceService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  // End open close
  ngOnInit() {    
    //const menus = new Menu[];
    const menus = Array<Menu>();
    type MyArrayType = Array<{path: string, title: string, icon: string, class: string, extralink: boolean, submenu: []}>;
    
    const arr: MyArrayType = [
      {path: '', title: 'Personal', icon: 'mdi mdi-dots-horizontal', class: 'nav-small-cap', extralink: true, submenu: []},
      {path: '', title: 'Personal', icon: 'mdi mdi-dots-horizontal', class: 'nav-small-cap', extralink: true, submenu: []}
    ];

    const output = this.service.obtenerMenu().subscribe( (res: any) => {
      
      for (let numero of res.data){
        //const menu = new Menu();
       
        if(numero.path == 'home') {
        const menu = {} as Menu;
        menu.path = '';
        menu.title = 'UI Components';
        menu.icon = 'mdi mdi-dots-horizontal';
        menu.class = 'nav-small-cap';
        menu.extralink = true;
        menu.submenu = [];

        //arr.push(menu);
        }
      }
      
    },
    err => console.log("sds")
  );
   
     //this.sidebarnavItems = menus.filter(sidebarnavItem => sidebarnavItem);
    //this.sidebarnavItems = arr.filter(sidebarnavItem => sidebarnavItem);
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }
}
