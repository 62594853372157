import { Component } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { MyserviceService } from '../services/myservice.service';
import { stringify } from 'querystring';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.css'],
  providers: [MyserviceService]
})
export class LoginComponent {
  msg = '';
  staticAlertClosedLogin = true;
  alertLogin:IAlert;
  development_indicator:String;
  hidden_denvironment_text:boolean;

  constructor(
    private authService: AuthService,
    private service: MyserviceService,
    private routes: Router) {

      this.alertLogin = {
        id: 1,
        type: 'danger',
        message: 'Se requiere seleccionar todos los campos..'
      };
    }

  loginform = true;
  recoverform = false;
  ennableInputs = false;
  ennableSpinner = true;

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }

  check(uname: string, p: string) {
    this.ennableInputs = true;
    this.ennableSpinner = false;
    this.staticAlertClosedLogin = true;
    sessionStorage.setItem('access_token','temporal');

    if(uname.length > 0 && p.length > 0) {

      const output = this.service.checkusernameandpassword(uname, p).subscribe( (res: any) => {
        if (res.code == 200) {
          localStorage.setItem('username', 'dff')
          this.routes.navigate(['/starter']);
          sessionStorage.setItem('access_token', res.data.access_token);
          sessionStorage.setItem('refresh_token', res.data.refresh_token);
          this.staticAlertClosedLogin = false;
          this.alertLogin.type = 'success';
          this.alertLogin.message = 'Bienvenido...';
          this.resetLogin();

          this.ennableSpinner = true;
          this.ennableInputs = false;
        } else {
          this.staticAlertClosedLogin = false;
          this.alertLogin.type = 'danger';
          this.alertLogin.message = 'Error de login.';
          this.resetLogin();

          this.msg = 'Ocurrio un error !!!';
          this.ennableSpinner = false;
          this.ennableInputs = true;
        }
      },(err: any) => {
          this.staticAlertClosedLogin = false;
          this.alertLogin.type = 'danger';
          this.alertLogin.message = 'Usuario o contraseña incorrecto.';
          this.ennableSpinner = true;
          this.ennableInputs = false;
          this.resetLogin();
        }
      );
      /*
      if (true) {
        this.routes.navigate(['/starter']);
      } else {
        this.msg = 'Invalid Username or Password';
      }*/
    } else {
      this.staticAlertClosedLogin = false;
      this.alertLogin.type = 'danger';
      this.alertLogin.message = 'Se requiere usuario y contraseña.';
      this.resetLogin();
      this.ennableSpinner = true;
      this.ennableInputs = false;
    }
  }

  checkUser(uname: string) {

    console.log("entro");
    sessionStorage.setItem('access_token','temporal');

    //this.msgRecuperaPass = 'se requiere metodo para validar usuario';

    const output = this.service.checkUsername(uname).subscribe( (res: any) => {
      console.log(res);

      /*if (res.code == 200) {
        this.msgRecuperaPass = 'Enviando correo con la contraseña...';
      }else {
         this.msgRecuperaPass = 'Ocurrio un error';
      }*/
    },
     //err => this.msgRecuperaPass = 'El Usuario no existe' + err.toString()

     );

  }
  resetLogin(){
    setTimeout(() => {this.staticAlertClosedLogin = true}, 1500);
  }
}
export interface IAlert {
  id: number;
  type: string;
  message: string;
}
