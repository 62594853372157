import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/shareReplay';


@Injectable()
export class AuthService {

  constructor(private httpClient: HttpClient, private router: Router) { }

  login(username: string, password: string) {
    console.log("--------login");
    sessionStorage.setItem('access_token', 'temporal');
    const params = new HttpParams({
      fromObject: {
        user_email: username,
        user_password: password,
        language_id: '1'
      }
    });
    return this.httpClient.post('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/both/login',
      params,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      }).do((authResult: any) => {
        if (authResult.code == '200') {
          console.log('/starter ');// + authResult.data.access_token);
          sessionStorage.setItem('access_token', authResult.data.access_token);
          sessionStorage.setItem('refresh_token', authResult.data.refresh_token);
          this.router.navigate(['/starter']);
        }
      })
      .shareReplay();
  }

  refreshToken(access_token: string, refresh_token: string) {

    const params = new HttpParams({
      fromObject: {
        token: access_token,
        refresh_token: refresh_token
      }
    });
    return this.httpClient.post('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/both/refresh-token',
      params,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      }).do((authResult: any) => {
        sessionStorage.setItem('access_token', authResult.data.access_token);
        sessionStorage.setItem('refresh_token', authResult.data.refresh_token);
      })
      .shareReplay();
  }

  logout() {
    // remove user from local storage to log user out
    //localStorage.getItem('user_id')
    console.log(localStorage.getItem('user_id'));
    const params = new HttpParams({
      fromObject: {
        user_id: '1'
      }
    });
    return this.httpClient.post('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/both/logout',
      params,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      }).do((data) => {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
        localStorage.removeItem('customer');
        localStorage.removeItem('customerBranch');
        localStorage.removeItem('user');
        localStorage.removeItem('branch_office_name');
        localStorage.removeItem('menu');
        localStorage.removeItem('submenu');
        localStorage.removeItem('esEmpleado');
        localStorage.removeItem('company1_id');
        localStorage.removeItem('company_branch_id');
        localStorage.removeItem('customerBranchId');
        //Se elimina menu
        localStorage.removeItem('titleMenu');
        this.router.navigate(['/login']);
      });
  }
}
