import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {

  @Input() layout;
  @Input() menuData;
  viewBreadcrump = true;
  pageInfo = {
    title:  '',
    data: {
      urls: [{title: '', url: ''},
             {title: '', url: ''}
            ]
    }
  };
  menuDefault = {
    title:'Consultas',
    url:'',
    submenu: {
      title:'Reportes',
      url:'/Reportes',
    }
  }


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.titleService.setTitle(event['title']);
        //this.pageInfo = event;
      });
  }
  ngOnInit() {}
}
